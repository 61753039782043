import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((_theme) => ({
  nav: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    "& > div": {
      flexGrow: 1,
      flexShrink: 1,
      height: "6px",
      background: "#54606F",
      opacity: "0.3",
      "&.active": {
        opacity: 1,
      },
    },
  },
}));

const NavStepper = ({ stepsLength = 1, activeStep = 0, goToStep = null }) => {
  const classes = useStyles();
  return (
    <div className={classes.nav}>
      {Array.from(new Array(stepsLength)).map((_, index) => (
        <div className={index === activeStep ? "active" : ""} key={index}></div>
      ))}
    </div>
  );
};

export default NavStepper;
