import { makeStyles } from "@material-ui/core";
import ModalWrapper from "@View/components/ModalWrapper";
import React from "react";
import { useTranslation } from "react-i18next";
import AppAPI from "@API/index";
import ModalTittle from "@View/components/ModalTittle";
import { downloadFinDataTemplateFileFunc } from "@Utils/downloadLinks";
import NavStepper from "@View/components/ui/NavSteper";
import NButton from "@View/components/ui/NButton";
import FileUploader from "@View/components/ui/FileUploader";
import LoadingIcon from "@View/components/svgComponents/LoadingIcon";

const acceptedFormats = ["xls", "xlsx", "csv", "xml"];
const maxFileSize = 4;

const useStyles = makeStyles((_theme) => ({
  input: {
    "&": {
      transition: "0.3s ease",
    },
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  cover: {
    color: "#54606F",
    fontSize: "13px",
    marginTop: "15px",
    "& > div + div": {
      marginTop: "10px",
    },
  },
  loading: {
    padding: "25px 0",
    textAlign: "center",
    color: "rgba(84, 96, 111, 1)",
    fontSize: "13px",
    "& > div": {
      paddingBottom: "25px",
    },
  },
}));

const BankStatements1CModal = ({ open, handleClose, client = null }) => {
  const userId = client?.id;

  const [selectedFile, setSelectedFile] = React.useState(null);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);

  const classes = useStyles();

  const submitForm = async () => {
    setIsLoading(true);

    const selectedFileType = selectedFile.name.toLowerCase().split('.').pop();
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("clientId", userId);

    try {
      await AppAPI.request({
        url: `/api/FinDataRequest/create-transaction-request/${selectedFileType}`,
        method: "POST",
        data: formData,
      });
    } catch (e) {
      // do nothing
    }
    setIsLoading(false);
  };

  return (
    <ModalWrapper open={open} handleClose={handleClose} maxWidth={"540px"}>
      <ModalTittle>finData1C.title</ModalTittle>
      <NavStepper activeStep={currentStep} stepsLength={2} />
      {isLoading ? (
        <div className={classes.loading}>
          <div>{t("attach.processing")}</div>
          <LoadingIcon />
        </div>
      ) : (
        <div className={classes.cover}>
          <div>{t("finData1C.text_1")}</div>
          <div>
            <ol>
              <li>{t("finData1C.list.item_1")}</li>
              <li>{t("finData1C.list.item_2")}</li>
              <li>{t("finData1C.list.item_3")}</li>
            </ol>
          </div>
          <div>{t("finData1C.addition")}</div>
          <div>{t("finData1C.addition_bills")}</div>
          <FileUploader
            acceptedFormats={acceptedFormats}
            maxFileSize={maxFileSize}
            multiple={false}
            onChange={(file) => setSelectedFile(file)}
          />
          <div style={{ marginTop: "25px" }}>
            <NButton
              disabled={!selectedFile}
              styles={{ maxWidth: "270px", margin: "0 auto" }}
              onClick={() => submitForm()}
            >
              {t("attach.upload")}
            </NButton>
          </div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <a
              href="#"
              style={{
                color: "rgba(111, 164, 242, 1)",
                fontWeight: "600",
                fontSize: "15px",
                textDecoration: "none",
              }}
              onClick={() => downloadFinDataTemplateFileFunc(userId)}
            >
              {t("attach.getSamples")}
            </a>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

export default BankStatements1CModal;
