import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DeleteModal } from "@View/components/DeleteModal";
import { connect, useDispatch } from "react-redux";
import { deleteClient } from "@State/";
import DataTable from "@View/components/table/DataTable";
import { usePageRoot } from "@View/hooks/usePageRoot";
import { selectCustomerId } from "@State/selectors";
import { Link } from "react-router-dom";
import { Grid, Tooltip } from "@material-ui/core";
import DetailsIcon from "@View/components/svgComponents/DetailsIcon";
import EditIcon from "@View/components/svgComponents/EditIcon";
import DeleteIcon from "@View/components/svgComponents/DeleteIcon";
import ButtonAddNew from "@View/components/buttons/ButtonAddNew";
import NewClientPage from "@View/pages/AdminPO/client/NewClientPage";
import EditClientPage from "@View/pages/AdminPO/client/EditClientPage";
import NewRequestPage from "@View/pages/AdminPO/request/NewRequestPage";
import NewRequestIcon from "@View/components/svgComponents/NewRequestIcon";
import { useTooltipStyle } from "@View/style/components/tooltipStyles";
import { ColorButton } from "@Components/button/ColorButton";
import { canCreateRequest, canEditEverything } from "@Security/stateHelpers";
import ButtonBB from "@View/components/buttons/ButtonBB";
import { downloadFile2Func } from "@Utils/downloadLinks";
import ExcelIcon from "@View/components/svgComponents/ExcelIcon";
import { getPointColorFromString } from "@Utils/colorPicker";

const mapStateToProps = (state) => {
  return {
    customerId: selectCustomerId(state),
    customerName: state.customer.customer.name,
    clients: state.customer?.customer?.clients ?? [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteClient: (clientId) => dispatch(deleteClient(clientId)),
  };
};

const ClientsTable = ({ clients, deleteClient, customerId, customerName }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [deletedId, setDeletedId] = useState();
  const [clientId, setClientId] = useState();
  const tooltipStyle = useTooltipStyle();

  const handleOpenModal = (event, clientId) => {
    event.preventDefault();
    setDeletedId(clientId);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteClient(deletedId);
    setDeletedId();
  };

  const [openNew, setOpenNew] = React.useState(false);

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [openRequest, setOpenRequest] = React.useState(false);

  const handleOpenRequest = () => {
    setOpenRequest(true);
  };

  const handleCloseRequest = () => {
    setOpenRequest(false);
  };

  const headTableClients = [
    {
      field: "externalId",
      headerName: t("inputs.External Id"),
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "creditRating",
      headerName: t("inputs.CreditScore"),
      width: 200,
      headerAlign: "left",
      align: "left",
      cellClassName: "tbl-align-left",
      renderCell: (cell) => {
        return (
          <ColorButton
            style={{
              color: getPointColorFromString(cell.row.creditRatingColor),
            }}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/client/details/${cell.row.id}`, {
                creditScoreOpen: true,
              });
              // history.push(`/creaditOpinion/${cell.row.id}`);
            }}
            disabled={false}
          />
        );
      },
    },
    {
      field: "riskSignalColor",
      headerName: t("inputs.RiskSignal"),
      width: 200,
      headerAlign: "left",
      align: "left",
      cellClassName: "tbl-align-left",
      renderCell: (cell) => {
        return (
          <ColorButton
            style={{ color: getPointColorFromString(cell.row.riskSignalColor) }}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/client/risk/${cell.row.id}`);
            }}
            disabled={false}
          />
        );
      },
    },
    {
      field: "name",
      headerName: t("inputs.CompanyName"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: " ",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (cell) => {
        return (
          <div className="tableIcons">
            {canCreateRequest() && (
              <Tooltip title={t("h1.New Request")} classes={tooltipStyle}>
                <div
                  onClick={() => {
                    setClientId(cell.row.id);
                    handleOpenRequest();
                  }}
                  className={tooltipStyle.element}
                >
                  <NewRequestIcon />
                </div>
              </Tooltip>
            )}
            <Tooltip title={t("buttons.Details")} classes={tooltipStyle}>
              <Link
                to={`/client/details/${cell.row.id}`}
                className={tooltipStyle.element}
              >
                <DetailsIcon />
              </Link>
            </Tooltip>
            {canEditEverything() && (
              <>
                <Tooltip title={t("buttons.Edit")} classes={tooltipStyle}>
                  <div
                    onClick={() => {
                      setClientId(cell.row.id);
                      handleOpenEdit();
                    }}
                    className={tooltipStyle.element}
                  >
                    <EditIcon />
                  </div>
                </Tooltip>
                <Tooltip title={t("buttons.Delete")} classes={tooltipStyle}>
                  <div
                    onClick={(event) => handleOpenModal(event, cell.row.id)}
                    className={tooltipStyle.element}
                  >
                    <DeleteIcon />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <NewClientPage
        customerId={customerId}
        open={openNew}
        handleOpen={handleOpenNew}
        handleClose={handleCloseNew}
      />
      {clientId != undefined ? (
        <EditClientPage
          clientId={clientId}
          open={openEdit}
          handleOpen={handleOpenEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {openRequest ? (
        <NewRequestPage
          clientId={clientId}
          customerId={customerId}
          open={openRequest}
          handleOpen={handleOpenRequest}
          handleClose={handleCloseRequest}
        />
      ) : null}
      <DataTable
        rows={clients}
        columns={headTableClients}
        toolbarButtons={
          <>
            <ButtonBB
              onClick={() => downloadFile2Func(customerId)}
              startIcon={<ExcelIcon />}
            >
              {t("buttons.ExportToExcelCase")}
            </ButtonBB>
            <ButtonAddNew onClick={handleOpenNew} />
          </>
        }
      />
      <DeleteModal
        handleDelete={handleDelete}
        openModal={deletedId !== undefined}
        handleCloseModal={handleCloseModal}
      />
    </React.Fragment>
  );
};

ClientsTable.propTypes = {
  clients: PropTypes.array.isRequired,
  deleteClient: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsTable);
