import { getClientRequest } from "../actionsGet/actionGetClient";
import AppAPI from "@API/index";

export const editClient = (body, history) => {
  const trimmedBody = {
    id: body.id,
    name: body.name.trim(),
    externalId: body.externalId.trim(),
    type: body.type,
    email: body.email.trim(),
    status: body.status.trim(),
    apiKey: body.apiKey,
    customerId: body.customerId,
  };

  return (dispatch) => {
    AppAPI.request({
      method: "put",
      url: `/api/Client`,
      data: JSON.stringify(trimmedBody),
    })
      .then(() => {
        dispatch(getClientRequest());
        window.location.reload()
      })
  };
};
