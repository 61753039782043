import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { Snackbar } from "@State/actions/actionCreators";
import { postNewUser } from "@State/index";
import {
  selectClientId,
  selectCustomerId,
  selectUserId,
} from "@State/selectors";
import { FetchOnceAutocomplete } from "@View/common/form/control/FetchOnceAutocomplete";
import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import DefaultSelect from "@View/components/formInputs/DefaultSelect";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import { ROLE_PAGES } from "@View/constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CopyIcon } from "@View/components/svgComponents/CopyIcon";
import { searchClients } from "@Utils/commonAPI";
import AppAPI from "@API/index";
import { Checkbox } from "@Components/form/control/Checkbox";
import Typography from "@material-ui/core/Typography";

const generatePassword = () => Math.random().toString(36).slice(-8);

const NewUserPage = ({
  customerId,
  prevPage,
  open,
  handleOpen,
  handleClose,
}) => {
  const initiatorIdSelectors = {
    [ROLE_PAGES.user]: selectUserId,
    [ROLE_PAGES.users]: selectUserId,
    [ROLE_PAGES.client]: selectClientId,
    [ROLE_PAGES.customer]: selectCustomerId,
  };

  const history = useHistory();
  const currentLocation = useLocation();
  const dispatch = useDispatch();
  const initiatorSelector = initiatorIdSelectors[prevPage];
  const initiatorId = useSelector(initiatorSelector);
  const { t } = useTranslation();

  const deleteObjFunc = (roleV) => {
    if (roleV === "Admin") {
      setNewData((prevData) => {
        let newData = { ...prevData };
        delete newData.CustomerId;
        delete newData.ClientId;
        delete newData.clientName;
        delete newData.customerName;
        return newData;
      });
    } else if (
      roleV === "CustomerUser" ||
      roleV === "CustomerGuest" ||
      roleV === "CustomerManager"
    ) {
      setNewData((prevData) => {
        let newData = { ...prevData };
        delete newData.ClientId;
        delete newData.clientName;
        return newData;
      });
    } else if (
      roleV === "ClientUser" ||
      roleV === "ClientGuest" ||
      roleV === "ClientManager"
    ) {
      setNewData((prevData) => {
        let newData = { ...prevData };
        delete newData.CustomerId;
        delete newData.customerName;
        return newData;
      });
    }
  };

  const [newData, setNewData] = useState({
    role: prevPage === "customer" ? "CustomerUser" : "ClientUser",
    userName: "",
    fullName: "",
    email: "",
    password: "",
    prevPage,
    initiatorId,
  });

  const handleChange = (value, label = null) => {
    if (Object.is(label, null)) {
      setNewData({ ...newData, ...value });
    } else {
      setNewData({ ...newData, [label]: value });
    }
  };

  const handleClientChange = (value) => {
    let obj = {
      ClientId: value.id,
      clientName: value.name,
    };
    setNewData({ ...newData, ...obj });
  };

  const handleCustomerChange = (value) => {
    let obj = {
      CustomerId: value.id,
      customerName: value.name,
    };
    setNewData({ ...newData, ...obj });
  };

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleClick = (event) => {
    event.preventDefault();
    if (validateEmail(newData.email)) {
      event.preventDefault();
      handleClose();
      dispatch(postNewUser(newData, history));
    } else {
      dispatch(Snackbar({ message: "snackbar.error", open: true }));
    }
  };

  let types = [
    { value: "Admin" },
    { value: "ClientUser" },
    { value: "ClientManager" },
    { value: "CustomerUser" },
    { value: "CustomerManager" },
  ];
  if (currentLocation.pathname.includes("/client/details/")) {
    types = [{ value: "ClientUser" }, { value: "ClientGuest" }];
  } else if (currentLocation.pathname.includes("/customer/details/")) {
    types = [{ value: "CustomerUser" }, { value: "CustomerGuest" }];
  }
  const typesWithBlankPassword = [
    "ClientUser",
    "ClientGuest",
    "ClientManager",
    "CustomerUser",
    "CustomerGuest",
    "CustomerManager",
  ];
  const [pickRole, setRole] = useState(newData.role);
  const fetchCustomerOptions = () =>
    AppAPI.request({
      url: "/api/Customer/search",
    }).then((result) => result.map((e) => ({ id: e.guid, name: e.name })));

  const [clientError, setClientError] = useState(false);

  let getDefClientId = async () => {
    let res = await searchClients();
    let idx = res.findIndex((el) => el.id === newData.initiatorId);
    if (!idx) return;
    let obj = {
      ClientId: res[idx].id,
      clientName: res[idx].name,
    };
    setNewData({ ...newData, ...obj });
  };

  let getDefCustomerId = async () => {
    let res = await fetchCustomerOptions();
    let idx = await res.findIndex((el) => el.id === newData.initiatorId);
    let obj = {
      CustomerId: res[idx].id,
      customerName: res[idx].name,
    };
    setNewData({ ...newData, ...obj });
  };

  React.useEffect(() => {
    if (prevPage === "customer") {
      getDefCustomerId();
    } else if (prevPage === "client") {
      getDefClientId();
    }
  }, []);

  const handleCopyText = () => {
    navigator.clipboard.writeText(newData.password);
  };

  const [isPassVisible, setIsPassVisible] = useState(false);

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <ModalTittle children2={""}>h1.New User</ModalTittle>
      <form onSubmit={(event) => handleClick(event)}>
        <DefaultInput
          value={newData.userName}
          label="Login"
          onChange={(value) => handleChange(value, "userName")}
        />
        <DefaultInput
          value={newData.email}
          label="inputs.Email"
          onChange={(value) => handleChange(value, "email")}
        />
        <DefaultInput
          value={newData.fullName}
          label="FullName"
          onChange={(value) => handleChange(value, "fullName")}
        />
        <FormControl className={"PassInput"}>
          <InputLabel htmlFor="standard-adornment-password">
            {t("Password")}
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={"text"}
            value={newData.password}
            disabled={
              typesWithBlankPassword.includes(newData.role) && !isPassVisible
            }
            onChange={(event) => handleChange(event.target.value, "password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleCopyText}
                  onMouseDown={null}
                >
                  <CopyIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {typesWithBlankPassword.includes(newData.role) ? (
          <>
            <Typography
              style={{
                color: "#A1ADBA",
                fontSize: "14px",
              }}
            >
              {typesWithBlankPassword.includes(newData.role)
                ? t("passOnFirstLoginSetup")
                : ""}
            </Typography>
            <div style={{ paddingBottom: 15 }}>
              <Checkbox
                label={t("setPass")}
                value={isPassVisible}
                onChange={(value) => {
                  setIsPassVisible(value);
                  handleChange(value ? generatePassword() : "", "password");
                }}
                plain
              />
            </div>
          </>
        ) : null}
        {/* <DefaultInput
          value={newData.password}
          label="Password"
          onChange={(value) => handleChange(value, "password")} /> */}
        <DefaultSelect
          value={`${newData.role}`}
          label="inputs.Role"
          style={{ width: "100%" }}
          dropdowns={types}
          onChange={(value) => {
            handleChange({
              role: value,
              password: typesWithBlankPassword.includes(value)
                ? ""
                : generatePassword(),
            });
            setRole(value);
            deleteObjFunc(value);
            setIsPassVisible(!typesWithBlankPassword.includes(value));
          }}
        />

        {pickRole === "CustomerUser" ||
        pickRole === "CustomerGuest" ||
        pickRole === "CustomerManager" ? (
          <FetchOnceAutocomplete
            value={
              newData.customerName === undefined
                ? ""
                : `${newData.customerName}`
            }
            id="customer"
            label={t("Customer")}
            onChange={handleCustomerChange}
            fetchOptions={fetchCustomerOptions}
            error={clientError}
          />
        ) : null}

        {pickRole === "ClientUser" ||
        pickRole === "ClientGuest" ||
        pickRole === "ClientManager" ? (
          <FetchOnceAutocomplete
            value={
              newData.clientName === undefined ? "" : `${newData.clientName}`
            }
            id="client"
            label={t("Client")}
            onChange={handleClientChange}
            fetchOptions={searchClients}
            error={clientError}
          />
        ) : null}

        <ButtonSave>buttons.Save</ButtonSave>
      </form>
    </ModalWrapper>
  );
};

export default NewUserPage;
