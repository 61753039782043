import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getCustomer } from "@State/index";
import CustomerDetails from "./CustomerDetails";
import CustomerExtendedDetails from "./CustomerExtendedDetails";
import { getHeaderLeftComponent } from "@View/components/headers/HeaderMain";
import { changeAppLanguage } from "@Localization/utils";
import WarningIcon from "@mui/icons-material/Warning";

const CustomerDetailsPage = () => {
  const { id: customerId } = useParams();
  const customer = useSelector((state) => state.customer.customer);
  const dispatch = useDispatch();

  const [openDetails, setOpenDetails] = React.useState(false);

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  /** @todo move outside */
  const LeftComponent = () => {
    const { t } = useTranslation();
    const today = new Date();
    const subscriptionEnd = customer.subscriptionUntil
      ? new Date(customer.subscriptionUntil)
      : new Date();
    const showWarning =
      customer.requestsLeft === 0 || subscriptionEnd - today > 0;
    return (
      <div
        style={{
          marginLeft: "24px",
        }}
      >
        <div
          onClick={() => handleOpenDetails()}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              marginRight: "15px",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "19px",
              color: "#1A1E24",
            }}
          >
            {customer.name}
          </div>
          <div style={{ display: "flex" }}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.25 4.25H8.75V5.75H7.25V4.25ZM8 11.75C8.4125 11.75 8.75 11.4125 8.75 11V8C8.75 7.5875 8.4125 7.25 8 7.25C7.5875 7.25 7.25 7.5875 7.25 8V11C7.25 11.4125 7.5875 11.75 8 11.75ZM8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        <Link
          to={`/payment?customerId=${customer.id}`}
          style={{
            fontSize: "12px",
            color: showWarning ? "orange" : "#54606F",
            position: "relative",
            top: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "4px",
            cursor: "pointer",
          }}
          title={t("payment.topUp")}
        >
          {showWarning && (
            <WarningIcon sx={{ color: "orange", fontSize: 20 }} />
          )}
          <div>
            {t("payment.requestInfo")} {customer.requestsLeft}/
            {customer.totalRequests}
          </div>
        </Link>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getCustomer(customerId));
  }, [customerId]);

  /** @todo think about different way for displaying this component, avoid store components in state */
  React.useEffect(() => {
    if (customer.defaultLanguage) {
      changeAppLanguage(customer.defaultLanguage);
    }
    dispatch(getHeaderLeftComponent(LeftComponent));
  }, [customer]);

  if (Object.keys(customer).length === 0) return <></>;

  return (
    <div className="customerDetails__page">
      <CustomerDetails
        open={openDetails}
        handleOpen={handleOpenDetails}
        handleClose={handleCloseDetails}
        customer={customer}
      />
      <CustomerExtendedDetails customer={customer} />
    </div>
  );
};

export default CustomerDetailsPage;
