import { FormControlLabel, Checkbox as MUiCheckbox } from "@material-ui/core";

export function Checkbox({ label, value, onChange, plain }: CheckboxProps) {
  return (
    <FormControlLabel
      className={plain ? "CheckboxPlain" : "Checkbox"}
      onChange={(_, checked) => onChange(checked)}
      label={<span>{label}</span>}
      control={<MUiCheckbox checked={value} name={label} color="primary" />}
    />
  );
}

type CheckboxProps = {
  label: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  plain?: boolean;
};
