import { makeStyles } from "@material-ui/core";
import { Snackbar } from "@State/actions/actionCreators";
import ButtonBB from "@View/components/buttons/ButtonBB";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AppAPI from "@API/index";
import ModalTittle from "@View/components/ModalTittle";
import { downloadFinDataTemplateFileFunc } from "@Utils/downloadLinks";
import ButtonUpload from "@View/components/buttons/ButtonUpload";

const UploadFileModal = ({
  open,
  handleOpen,
  handleClose,
  openBankStatements,
  customer = null,
  client = null,
}) => {
  const reportId = (customer || client)?.id;

  const { t } = useTranslation();
  const fileInput = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);

  const useStyles = makeStyles((_theme) => ({
    input: {
      "&": {
        transition: "0.3s ease",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04) !important",
      },
    },
  }));

  const classes = useStyles();

  const sendFileFunc = async () => {
    let file = fileInput?.current?.files[0];
    if (file !== undefined) {
      const formData = new FormData();
      formData.append("file", file);
      let res = await AppAPI.request({
        url: "/api/FinDataRequest/file-test",
        method: "POST",
        data: formData,
      });

      if (res.requestId) {
        history.push(`/findatarequest/${res.requestId}/data`);
        return;
      }

      if (typeof res === "string") {
        dispatch(Snackbar({ message: "snackbar.success", open: true }));
      }
    }
  };

  const openBankStatementsLocal = () => {
    handleClose();
    openBankStatements();
  };

  const uploadFileForm = async (files) => {
    setIsLoading(true);
    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("clientId", reportId);

    try {
      await AppAPI.request({
        url: "/api/FinDataRequest/create-transaction-request",
        method: "POST",
        data: formData,
      });
    } catch (e) {
      // do nothing
    }
    setIsLoading(false);
  };

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <ModalTittle>buttons.Upload Financial Data</ModalTittle>
      <div style={{ marginBottom: "15px", marginTop: "5px" }}>
        <div
          className={classes.input}
          style={{
            marginTop: "15px",
            width: "100%",
            display: "flex",
            border: "1px solid #E1E6F1",
            borderRadius: "8px",
            textTransform: "none",
            transition: "0.3s ease",
            backgroundColor: "#fff",
          }}
        >
          <label
            style={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "17px",
              color: "#54606F",
              width: "100%",
              height: "100%",
              padding: "15px 20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <input
              onChange={(e) => sendFileFunc()}
              style={{
                display: "none",
                fontSize: 0,
                background: "transparent",
                visibility: "hidden",
              }}
              ref={fileInput}
              type="file"
            />
            {t("buttons.Upload File")}
          </label>
        </div>
      </div>
      <ButtonBB
        uploadFile
        disabled={isLoading}
        style={{ width: "100%", marginBottom: "15px" }}
        fullWidth
        onChange={uploadFileForm}
        accept={".txt"}
      >
        {t("buttons.bankStatements1C")}
      </ButtonBB>
      <ButtonBB
        disabled={false}
        style={{ width: "100%", marginBottom: "15px" }}
        fullWidth
        onClick={() => openBankStatementsLocal()}
      >
        {t("finData1C.title")}
      </ButtonBB>
      <div style={{ textAlign: "center" }}>
        <a
          href="#"
          style={{ width: "100%" }}
          onClick={() => downloadFinDataTemplateFileFunc(reportId)}
        >
          {t("buttons.fileType")}
        </a>
      </div>
    </ModalWrapper>
  );
};

export default UploadFileModal;
