import {
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  SET_CHECKBOX_DATA,
} from "../actionTypes";
import AppAPI from "@API/index";

export const setCheckBoxData = (payload) => {
  return {
    type: SET_CHECKBOX_DATA,
    payload: payload,
  };
};

//fetch one customer
export const getCustomerRequest = () => {
  return {
    type: GET_CUSTOMER_REQUEST,
  };
};

const getCustomerSuccess = (customer) => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: customer,
  };
};

export const getCustomer = (id) => {
  return (dispatch) => {
    dispatch(getCustomerRequest());
    AppAPI.request({
      method: "get",
      url: `/api/Customer/${id}`,
    })
      .then((data) => {
        let customer = {
          clients: [],
          users: [],
          finDataRequests: data.finDataRequests,
          id: data.id,
          name: data.name,
          externalId: data.externalId,
          createdAt: data.createdAt,
          type: data.type,
          email: data.email,
          status: data.status,
          updatedAt: data.updatedAt,
          totalRequests: data.totalRequests,
          requestsLeft: data.requestsLeft,
          subscriptionUntil: data.subscriptionUntil,
          ServerUrl: data.ServerUrl,
          defaultLanguage: data.defaultLanguage,
        };

        data.clients?.forEach((client, index) => {
          customer.clients[index] = {};
          customer.clients[index].id = client.id;
          customer.clients[index].externalId = client.externalId;
          customer.clients[index].name = client.name;
          customer.clients[index].createdAt = client.createdAt.split("T")[0];
          customer.clients[index].accountSystemType = client.accountSystemType;
          customer.clients[index].creditRating = client.creditRating;
          customer.clients[index].creditRatingColor = client.creditRatingColor;
          customer.clients[index].riskSignalColor = client.riskSignalColor;
        });
        data.users?.forEach((user, index) => {
          customer.users[index] = {};
          customer.users[index].id = user.id;
          customer.users[index].status = user.status;
          customer.users[index].userName = user.userName;
          customer.users[index].fullName = user.fullName;
          customer.users[index].department = user.department;
          customer.users[index].createdAt = user.createdAt;
        });
        data.finDataRequests?.forEach((request, index) => {
          customer.finDataRequests[index] = {};
          customer.finDataRequests[index].clientId = request.clientId;
          customer.finDataRequests[index].clientExternalId =
            request.clientExternalId;
          customer.finDataRequests[index].clientName = request.clientName;
          customer.finDataRequests[index].id = request.id;
          customer.finDataRequests[index].createdAt = request.createdAt;
          customer.finDataRequests[index].updatedAt = request.updatedAt;
          customer.finDataRequests[index].startDate =
            request.startDate.split("T")[0];
          customer.finDataRequests[index].endDate =
            request.endDate.split("T")[0];
          customer.finDataRequests[index].finDatafile = request.finDataFile;
          customer.finDataRequests[index].status = request.status;
          customer.finDataRequests[index].multiplicity = request.multiplicity;
          customer.finDataRequests[index].reportType = request.reportType;
          customer.finDataRequests[index].transferType = request.transferType;
          customer.finDataRequests[index].type = request.type;
          customer.finDataRequests[index].language = request.language;
        });
        dispatch(getCustomerSuccess(customer));
      })
      .catch((error) => {
        // do nothing
      });
  };
};
