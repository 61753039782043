//fetch to all customers
export const GET_ALL_CUSTOMERS_REQUEST = "GET_ALL_CUSTOMERS_REQUEST";
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
export const GET_ALL_CUSTOMERS_FAILURE = "GET_ALL_CUSTOMERS_FAILURE";
//fetch to one client
export const GET_CLIENT_REQUEST = "GET_CLIENT_REQUEST";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILURE = "GET_CLIENT_FAILURE";

//fetch to one customer
export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";
//fetch to one finDataRequest
export const GET_FINDATA_REQUEST = "GET_FINDATA_REQUEST";
export const GET_FINDATA_SUCCESS = "GET_FINDATA_SUCCESS";
export const GET_FINDATA_FAILURE = "GET_FINDATA_FAILURE";

export const REQUEST_OPEN_DETAILS = "REQUEST/REQUEST_OPEN_DETAILS";
export const REQUEST_CLOSE_DETAILS = "REQUEST/REQUEST_CLOSE_DETAILS";
//fetch to one user
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_ALL_USERS = "GET_ALL_USERS";
//fetch to new apikey
export const GET_APIKEY_REQUEST = "GET_APIKEY_REQUEST";
export const GET_APIKEY_SUCCESS = "GET_APIKEY_SUCCESS";
export const GET_APIKEY_FAILURE = "GET_APIKEY_FAILURE";
//fetch to debt burden
export const GET_DEBT_BURDEN_REQUEST = "GET_DEBT_BURDEN_REQUEST";
export const GET_DEBT_BURDEN_SUCCESS = "GET_DEBT_BURDEN_SUCCESS";
export const GET_DEBT_BURDEN_FAILURE = "GET_DEBT_BURDEN_FAILURE";
//fetch to all codes
export const GET_ALL_CODES_REQUEST = "GET_ALL_CODES_REQUEST";
export const GET_ALL_CODES_SUCCESS = "GET_ALL_CODES_SUCCESS";
export const GET_ALL_CODES_FAILURE = "GET_ALL_CODES_FAILURE";
//fetch to code
export const GET_CODE_REQUEST = "GET_CODE_REQUEST";
export const GET_CODE_SUCCESS = "GET_CODE_SUCCESS";
export const GET_CODE_FAILURE = "GET_CODE_FAILURE";
//snackbar
export const SET_SNACKBAR = "SET_SNACKBAR";
export const SET_CHECKBOX_DATA = "checkbox/SET_CHECKBOX_DATA";

// editTable
export const SET_EDIT_TABLE_DATA = "SET_EDIT_TABLE_DATA";
//getFinDataRequestPageData
export const requestActionType = "FinDataRequestPageData/REQUEST";
export const successActionType = "FinDataRequestPageData/SUCCESS";
export const failureActionType = "FinDataRequestPageData/FAILURE";
//header
export const SET_HEADER_LEFT_COMPONENT = "header/SET_HEADER_LEFT_COMPONENT";

export enum ActionType {
  INIT = "INIT",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGOUT = "LOGOUT",
  CUSTOMER_REQUEST = "CUSTOMER_REQUEST",
  CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS",
  CUSTOMER_FAILURE = "CUSTOMER_FAILURE",
  CLIENT_REQUEST = "CLIENT_REQUEST",
  CLIENT_SUCCESS = "CLIENT_SUCCESS",
  CLIENT_FAILURE = "CLIENT_FAILURE",
  SET_SNACKBAR = "SET_SNACKBAR",
}
