import {
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  CollapseContainerRow,
  CollapseGroupRows,
  DefaultTable,
  FirstCell,
  NestedRow,
  NoCollapseGroupRows,
  TextCell,
} from "@View/common/table";
import ButtonBB from "@View/components/buttons/ButtonBB";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { NestedTable } from "./NestedTable";
import { mutationOfTableHandlers } from "@Utils/tableUtils";
import AppAPI from "@API/index";
import { canEditEverything } from "@Security/stateHelpers";
import { getHighlightColorForLastIndex } from "@Utils/colorPicker";
import { useCTAButtonStyle } from "@View/style/components/tooltipStyles";

const FinTable2 = ({
  transformedData,
  isHorAnEn,
  originalData,
  noTransformData,
  isVerticalAnalyseEnable,
  frequency,
  isEdit,
  setIsEdit,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const { id: finDataRequestId } = useParams();
  const [data, setData] = React.useState();
  const counter = React.useRef({ ...transformedData });
  const [originFinStatements, setOriginFinStatements] = React.useState();
  const [originalDataButton, setOriginalDataButton] = React.useState(true);
  const [disabled, setDisabled] = React.useState(true);
  const [clicked, setClicked] = React.useState(false);

  const getData = () => {
    setClicked(true);
    setOriginalDataButton(!originalDataButton);
    if (originalDataButton === true) {
      getEditData();
    } else {
      getOriginalData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let asyncFunc = async () => {
      if (originFinStatements) {
        let trs = originalData(originFinStatements.finData);
        setData(trs);
      }
    };
    asyncFunc();
  }, [frequency, isHorAnEn, startDate, endDate]);

  useEffect(() => {
    let asyncFunc = async () => {
      if (originFinStatements) {
        let trs = originalData(originFinStatements.finData);
        if (data !== undefined) {
          if (
            typeof data.balanceSheet.assets.currentAssets
              .cashEquivalents[1][0] !==
            typeof trs.balanceSheet.assets.currentAssets.cashEquivalents[1][0]
          ) {
            setData({ ...trs });
          }
        }
      }
    };
    asyncFunc();
  }, [isVerticalAnalyseEnable, data]);

  const getOriginalData = async () => {
    const response = await AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/financialStatements?isOriginal=true`,
    });
    setOriginFinStatements(JSON.parse(JSON.stringify(response)));

    let d = originalData(response.finData);
    setData(d);

    setClicked(false);
    return originFinStatements;
  };

  const getEditData = async () => {
    const response = await AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/financialStatements?isOriginal=false`,
    });
    setOriginFinStatements(JSON.parse(JSON.stringify(response)));

    let d = originalData(response.finData);
    setData(undefined);
    setData(d);

    setClicked(false);
    return originFinStatements;
  };

  const intangibleAssets = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/intangibleAssets`,
    });
  const fixedAssets = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/fixedassets`,
    });
  const receivables = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/receivables`,
    });
  const financialInvestments = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/financialinvestments`,
    });
  const longTermLoans = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/longtermloans`,
    });
  const longTermLoansOther = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/longtermdutiesother`,
    });
  const shortTermLoans = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/shorttermloans`,
    });
  const accountsPayable = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/accountspayable`,
    });
  const intangibleSearchAssets = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/intangiblesearchassets`,
    });
  const stocks = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/stocks`,
    });
  const cashequivalents = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/cashequivalents`,
    });
  const revenue = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/revenue`,
    });
  const otherincome = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/otherincome`,
    });
  const otherExpenses = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/otherexpenses`,
    });
  const administrativeExpenses = async () =>
    AppAPI.request({
      url: `/api/FinDataRequest/${finDataRequestId}/transcription/administrativeexpenses`,
    });

  const EditRow = ({ finData, k, level, isEdit, firstCell }) => {
    const useStyles = makeStyles(() => ({
      input: {
        background: "inherit",
        paddingTop: "0px !important",
        "& .MuiFormControl-root ": {
          height: "100%",
        },
        "& .MuiFormControl-root .MuiInputBase-root": {
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "inherit",
        },
        "& .MuiFormControl-root .Mui-focused": {
          outline: "rgb(25, 118, 210) solid 1px",
          outlineffset: "-1px",
        },
        "& input": {
          textAlign: "right",
        },
        "& .MuiInput-underline:before": {
          border: "none",
        },
        "& .MuiInput-underline:after": {
          border: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
          border: "none",
        },
        "& .MuiInput-root input": {
          color: "inherit",
        },
      },
    }));

    const classes = useStyles();

    function setValueWithDate(finData, path, index, value) {
      let obj = {
        date: finData.header[index],
        value: value,
      };

      Object.prototype.getValue = function (path2, value) {
        if (typeof path2 === "string") {
          var last = (path2 = path2.split(".")).splice(-1);
          [this].concat(path2).reduce(function (a, b) {
            return a[b];
          })[last] = value;
        }
      };
      let findIndex = noTransformData.findIndex(
        (el) => el.columnName === obj.date
      );
      noTransformData[findIndex].getValue(path, Number(value));

      return obj;
    }

    function setValue(finData, path, index, value) {
      if (isEdit === true) {
        Object.prototype.getValue = function (path2, value) {
          if (typeof path2 === "string") {
            var last = (path2 = path2.split(".")).splice(-1);
            [this].concat(path2).reduce(function (a, b) {
              return a[b];
            })[last] = value;
          }
        };

        const properties = path.split(".");
        let getData = finData;
        properties.forEach((el) => (getData = getData[el]));
        getData[index] = value;

        let way = [];
        properties.forEach((el, i) => way.push(el));
        way = way.reduce((a, b) => a + "." + b);
        let fullObj = finData;
        fullObj.getValue(way, getData);
        return fullObj;
      }
    }

    const handleChange = (c, i, k) => {
      if (!i) return;
      counter.current = setValue(data, k, i, c.target.value);
      setValueWithDate(data, k, i, c.target.value);
      setDisabled(false);
    };

    return (
      <>
        {firstCell && <FirstCell level={level}>{firstCell}</FirstCell>}
        {finData.map((e, i, arr) => {
          if (e === undefined || e === null) {
            e = 0;
          }

          if (typeof e === "object" && typeof e[0] === "object") {
            return (
              <TextCell
                className={classes.input}
                color={getHighlightColorForLastIndex(e, i, arr, isHorAnEn)}
                minWidth={e.length * 10 + "px"}
              >
                {e}
              </TextCell>
            );
          }
          return (
            <TextCell
              className={classes.input}
              color={getHighlightColorForLastIndex(e, i, arr, isHorAnEn)}
              minWidth={e.length * 10 + "px"}
            >
              <TextField
                style={{
                  color: getHighlightColorForLastIndex(
                    e,
                    i,
                    arr,
                    isHorAnEn,
                    true
                  ),
                  textDecoration:
                    isEdit === true && i !== 0 ? "underline" : "none",
                }}
                disabled={!isEdit}
                defaultValue={e}
                onChange={(c) => handleChange(c, i, k)}
              />
            </TextCell>
          );
        })}
      </>
    );
  };

  const onSubmit = async () => {
    setIsEditCover(!isEdit);
    try {
      await AppAPI.request({
        url: `/api/FinDataRequest/${finDataRequestId}/data`,
        method: "PUT",
        data: noTransformData,
      });
    } catch (e) {
      // do nothing
    }
  };

  const setIsEditCover = () => {
    setIsEdit(!isEdit);
    setTimeout(() => {
      if (typeof mutationOfTableHandlers.last === "function") {
        mutationOfTableHandlers.last();
      }
    }, 100);
  };

  const btnStyles = useCTAButtonStyle();

  if (data === undefined) return <></>;

  return (
    <>
      {canEditEverything() && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#fff",
            padding: "16px 20px",
            borderTop: "1px solid #E1E6F1",
            boxSizing: "border-box",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <ButtonBB
              style={!isEdit ? { background: "#fff", color: "#54606F" } : {}}
              className={isEdit ? btnStyles.default : ""}
              onClick={() => setIsEditCover()}
            >
              {isEdit ? "Редактировать вкл" : "Редактировать выкл"}
            </ButtonBB>
          </div>
          <div style={{ marginRight: "10px" }}>
            <ButtonBB disabled={disabled} onClick={() => onSubmit()}>
              Сохранить
            </ButtonBB>
          </div>
          <div style={{ marginRight: "10px" }}>
            <ButtonBB
              disabled={isEdit}
              style={{
                opacity: isEdit ? "0.5" : "1",
                ...(!originalDataButton
                  ? { background: "#fff", color: "#54606F" }
                  : {}),
              }}
              className={originalDataButton ? btnStyles.default : ""}
              onClick={() => getData()}
            >
              {originalDataButton
                ? "Показать не оригинал"
                : "Показать оригинал"}
            </ButtonBB>
          </div>
        </div>
      )}

      <DefaultTable fixTwoCell maxCellWidth="110px">
        <TableHead
          style={{
            background: "#F3F5F7",
            height: "46px",
          }}
        >
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "calc(2em + 6px)",
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "19px",
                color: "#54606F",
                textAlign: "left",
                border: "none",
              }}
            >
              {t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.balanceSheet"
              )}
            </TableCell>
            {transformedData.header.map((el, index) => (
              <TableCell
                style={{
                  fontWeight: 600,
                  fontSize: "13px",
                  lineHeight: "16px",
                  letterSpacing: "0.0075em",
                  color: "#54606F",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
                component="th"
                key={index}
              >
                {el}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <NoCollapseGroupRows color={'#fff'} paddingLeft={'2em'} cellText={t("finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.balanceSheet")}></NoCollapseGroupRows> */}

          <CollapseGroupRows
            k={"balanceSheet.assets.total"}
            EditRow={EditRow}
            isEdit={isEdit}
            colapse={true}
            isOpen={false}
            level={3}
            clickableCellText={t(
              "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.assets"
            )}
            otherCellsTexts={data.balanceSheet.assets.total}
          >
            <CollapseGroupRows
              k={"balanceSheet.assets.nonCurrentAssets.total"}
              EditRow={EditRow}
              isEdit={isEdit}
              level={4}
              clickableCellText={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.nonCurrentAssets"
              )}
              otherCellsTexts={data.balanceSheet.assets.nonCurrentAssets.total}
            >
              <CollapseContainerRow
                k={"balanceSheet.assets.nonCurrentAssets.intangibleAssets"}
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.intangibleAssets"
                )}
                otherCellsTexts={
                  data.balanceSheet.assets.nonCurrentAssets.intangibleAssets
                }
              >
                <NestedTable fetchEffect={intangibleAssets} />
              </CollapseContainerRow>
              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.assets.nonCurrentAssets.randDResults
                  }
                  k={"balanceSheet.assets.nonCurrentAssets.randDResults"}
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.randDResults"
                  )}
                  level={5}
                />
              </NestedRow>

              <CollapseContainerRow
                k={"balanceSheet.assets.nonCurrentAssets.intangibleAssets"}
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.intangibleSearchAssets"
                )}
                otherCellsTexts={
                  data.balanceSheet.assets.nonCurrentAssets
                    .intangibleSearchAssets
                }
              >
                <NestedTable fetchEffect={intangibleSearchAssets} />
              </CollapseContainerRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.assets.nonCurrentAssets
                      .tangibleSearchAssets
                  }
                  k={
                    "balanceSheet.assets.nonCurrentAssets.tangibleSearchAssets"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.tangibleSearchAssets"
                  )}
                  level={5}
                />
              </NestedRow>

              <CollapseContainerRow
                k={"balanceSheet.assets.nonCurrentAssets.fixedAssets"}
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.fixedAssets"
                )}
                otherCellsTexts={
                  data.balanceSheet.assets.nonCurrentAssets.fixedAssets
                }
              >
                <NestedTable fetchEffect={fixedAssets} />
              </CollapseContainerRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.assets.nonCurrentAssets
                      .profitableInvestments
                  }
                  k={
                    "balanceSheet.assets.nonCurrentAssets.profitableInvestments"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.profitableInvestments"
                  )}
                  level={5}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.assets.nonCurrentAssets
                      .financialInvestments
                  }
                  k={
                    "balanceSheet.assets.nonCurrentAssets.financialInvestments"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.financialInvestments"
                  )}
                  level={5}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.assets.nonCurrentAssets.deferredTax
                  }
                  k={"balanceSheet.assets.nonCurrentAssets.deferredTax"}
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.deferredTax"
                  )}
                  level={5}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={data.balanceSheet.assets.nonCurrentAssets.other}
                  k={"balanceSheet.assets.nonCurrentAssets.other"}
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.other"
                  )}
                  level={5}
                />
              </NestedRow>
            </CollapseGroupRows>
            <CollapseGroupRows
              k={"balanceSheet.assets.currentAssets.total"}
              EditRow={EditRow}
              isEdit={isEdit}
              level={4}
              clickableCellText={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.currentAssets"
              )}
              otherCellsTexts={data.balanceSheet.assets.currentAssets.total}
            >
              <CollapseContainerRow
                k={"balanceSheet.assets.currentAssets.stocks"}
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.stocks"
                )}
                otherCellsTexts={data.balanceSheet.assets.currentAssets.stocks}
              >
                <NestedTable fetchEffect={stocks} />
              </CollapseContainerRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.assets.currentAssets
                      .valueAddedTaxOnAcquiredValuables
                  }
                  k={
                    "balanceSheet.assets.currentAssets.valueAddedTaxOnAcquiredValuables"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.valueAddedTaxOnAcquiredValuables"
                  )}
                  level={5}
                />
              </NestedRow>

              <CollapseContainerRow
                k={"balanceSheet.assets.currentAssets.receivables"}
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.receivables"
                )}
                otherCellsTexts={
                  data.balanceSheet.assets.currentAssets.receivables
                }
              >
                <NestedTable fetchEffect={receivables} />
              </CollapseContainerRow>

              <CollapseContainerRow
                k={
                  "balanceSheet.assets.currentAssets.currentAssetFinancialInvestments"
                }
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.currentAssetFinancialInvestments"
                )}
                otherCellsTexts={
                  data.balanceSheet.assets.currentAssets
                    .currentAssetFinancialInvestments
                }
              >
                <NestedTable fetchEffect={financialInvestments} />
              </CollapseContainerRow>

              <CollapseContainerRow
                k={"balanceSheet.assets.currentAssets.cashEquivalents"}
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.cashEquivalents"
                )}
                otherCellsTexts={
                  data.balanceSheet.assets.currentAssets.cashEquivalents
                }
              >
                <NestedTable fetchEffect={cashequivalents} />
              </CollapseContainerRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.assets.currentAssets.currentAssetOther
                  }
                  k={"balanceSheet.assets.currentAssets.currentAssetOther"}
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.currentAssetOther"
                  )}
                  level={5}
                />
              </NestedRow>
            </CollapseGroupRows>
          </CollapseGroupRows>

          <CollapseGroupRows
            k={"balanceSheet.liabilities.total"}
            EditRow={EditRow}
            isEdit={isEdit}
            colapse={true}
            isOpen={false}
            level={3}
            clickableCellText={t(
              "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.liabilities"
            )}
            otherCellsTexts={data.balanceSheet.liabilities.total}
          >
            <CollapseGroupRows
              k={"balanceSheet.liabilities.capitalAndReserves.total"}
              EditRow={EditRow}
              isEdit={isEdit}
              level={4}
              clickableCellText={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.capitalAndReserves"
              )}
              otherCellsTexts={
                data.balanceSheet.liabilities.capitalAndReserves.total
              }
            >
              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.capitalAndReserves
                      .authorizedCapital
                  }
                  k={
                    "balanceSheet.liabilities.capitalAndReserves.authorizedCapital"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.authorizedCapital"
                  )}
                  level={4}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.capitalAndReserves
                      .revaluationOfNonCurrentAssets
                  }
                  k={
                    "balanceSheet.liabilities.capitalAndReserves.revaluationOfNonCurrentAssets"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.revaluationOfNonCurrentAssets"
                  )}
                  level={4}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.capitalAndReserves
                      .additionalCapital
                  }
                  k={
                    "balanceSheet.liabilities.capitalAndReserves.additionalCapital"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.additionalCapital"
                  )}
                  level={4}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.capitalAndReserves
                      .reserveCapital
                  }
                  k={
                    "balanceSheet.liabilities.capitalAndReserves.reserveCapital"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.reserveCapital"
                  )}
                  level={4}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.capitalAndReserves
                      .retainedEarnings
                  }
                  k={
                    "balanceSheet.liabilities.capitalAndReserves.retainedEarnings"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.retainedEarnings"
                  )}
                  level={4}
                />
              </NestedRow>
            </CollapseGroupRows>

            <CollapseGroupRows
              k={"balanceSheet.liabilities.longTermDuties.total"}
              EditRow={EditRow}
              isEdit={isEdit}
              level={4}
              clickableCellText={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.longTermDuties"
              )}
              otherCellsTexts={
                data.balanceSheet.liabilities.longTermDuties.total
              }
            >
              <CollapseContainerRow
                k={
                  "balanceSheet.liabilities.longTermDuties.longTermBorrowedFunds"
                }
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.longTermBorrowedFunds"
                )}
                otherCellsTexts={
                  data.balanceSheet.liabilities.longTermDuties
                    .longTermBorrowedFunds
                }
              >
                <NestedTable fetchEffect={longTermLoans} />
              </CollapseContainerRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.longTermDuties
                      .longTermDeferredTax
                  }
                  k={
                    "balanceSheet.liabilities.longTermDuties.longTermDeferredTax"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.longTermDeferredTax"
                  )}
                  level={5}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.longTermDuties
                      .longTermEstimated
                  }
                  k={
                    "balanceSheet.liabilities.longTermDuties.longTermEstimated"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.longTermEstimated"
                  )}
                  level={5}
                />
              </NestedRow>
              <CollapseContainerRow
                k={"balanceSheet.liabilities.longTermDuties.longTermOther"}
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.longTermOther"
                )}
                otherCellsTexts={
                  data.balanceSheet.liabilities.longTermDuties.longTermOther
                }
              >
                <NestedTable fetchEffect={longTermLoansOther} />
              </CollapseContainerRow>
            </CollapseGroupRows>

            <CollapseGroupRows
              k={"balanceSheet.liabilities.shortTermCommitments.total"}
              EditRow={EditRow}
              isEdit={isEdit}
              level={4}
              clickableCellText={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.shortTermCommitments"
              )}
              otherCellsTexts={
                data.balanceSheet.liabilities.shortTermCommitments.total
              }
            >
              <CollapseContainerRow
                k={
                  "balanceSheet.liabilities.shortTermCommitments.shortTermBorrowedFunds"
                }
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.shortTermBorrowedFunds"
                )}
                otherCellsTexts={
                  data.balanceSheet.liabilities.shortTermCommitments
                    .shortTermBorrowedFunds
                }
              >
                <NestedTable fetchEffect={shortTermLoans} />
              </CollapseContainerRow>
              <CollapseContainerRow
                k={
                  "balanceSheet.liabilities.shortTermCommitments.accountsPayable"
                }
                EditRow={EditRow}
                isEdit={isEdit}
                isHorAnEn={isHorAnEn}
                level={5}
                clickableCellText={t(
                  "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.accountsPayable"
                )}
                otherCellsTexts={
                  data.balanceSheet.liabilities.shortTermCommitments
                    .accountsPayable
                }
              >
                <NestedTable fetchEffect={accountsPayable} />
              </CollapseContainerRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.shortTermCommitments
                      .revenueOfTheFuturePeriods
                  }
                  k={
                    "balanceSheet.liabilities.shortTermCommitments.revenueOfTheFuturePeriods"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.revenueOfTheFuturePeriods"
                  )}
                  level={5}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.shortTermCommitments
                      .shortTermEstimated
                  }
                  k={
                    "balanceSheet.liabilities.shortTermCommitments.shortTermEstimated"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.shortTermEstimated"
                  )}
                  level={5}
                />
              </NestedRow>

              <NestedRow style={{ height: "50px" }}>
                <EditRow
                  finData={
                    data.balanceSheet.liabilities.shortTermCommitments
                      .shortTermOther
                  }
                  k={
                    "balanceSheet.liabilities.shortTermCommitments.shortTermOther"
                  }
                  isEdit={isEdit}
                  firstCell={t(
                    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.shortTermOther"
                  )}
                  level={5}
                />
              </NestedRow>
            </CollapseGroupRows>
          </CollapseGroupRows>

          <CollapseGroupRows
            paddingLeft={"2em"}
            cellStyles={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "19px",
              paddingLeft: "15px",
            }}
            noClickableCellText={t(
              "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.financialResults"
            )}
          ></CollapseGroupRows>

          <CollapseContainerRow
            k={"financialResults.revenue"}
            EditRow={EditRow}
            isEdit={isEdit}
            isHorAnEn={isHorAnEn}
            level={3}
            clickableCellText={t(
              "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.revenue"
            )}
            otherCellsTexts={data.financialResults.revenue}
          >
            <NestedTable fetchEffect={revenue} />
          </CollapseContainerRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.costOfSales}
              k={"financialResults.costOfSales"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.costOfSales"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.grossProfitTotal}
              k={"financialResults.grossProfitTotal"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.grossProfitTotal"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.businessExpenses}
              k={"financialResults.businessExpenses"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.businessExpenses"
              )}
              level={3}
            />
          </NestedRow>

          <CollapseContainerRow
            k={"financialResults.administrativeExpenses"}
            EditRow={EditRow}
            isEdit={isEdit}
            isHorAnEn={isHorAnEn}
            level={3}
            clickableCellText={t(
              "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.administrativeExpenses"
            )}
            otherCellsTexts={data.financialResults.administrativeExpenses}
          >
            <NestedTable fetchEffect={administrativeExpenses} />
          </CollapseContainerRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.profitFromSalesTotal}
              k={"financialResults.profitFromSalesTotal"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.profitFromSalesTotal"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.incomeFromParticipation}
              k={"financialResults.incomeFromParticipation"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.incomeFromParticipation"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.interestReceivable}
              k={"financialResults.interestReceivable"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.interestReceivable"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.percentageToBePaid}
              k={"financialResults.percentageToBePaid"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.percentageToBePaid"
              )}
              level={3}
            />
          </NestedRow>

          <CollapseContainerRow
            k={"financialResults.otherIncome"}
            EditRow={EditRow}
            isEdit={isEdit}
            isHorAnEn={isHorAnEn}
            level={3}
            clickableCellText={t(
              "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.otherIncome"
            )}
            otherCellsTexts={data.financialResults.otherIncome}
          >
            <NestedTable fetchEffect={otherincome} />
          </CollapseContainerRow>

          <CollapseContainerRow
            k={"financialResults.otherExpenses"}
            EditRow={EditRow}
            isEdit={isEdit}
            isHorAnEn={isHorAnEn}
            level={3}
            clickableCellText={t(
              "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.otherExpenses"
            )}
            otherCellsTexts={data.financialResults.otherExpenses}
          >
            <NestedTable fetchEffect={otherExpenses} />
          </CollapseContainerRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.profitBeforeTaxTotal}
              k={"financialResults.profitBeforeTaxTotal"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.profitBeforeTaxTotal"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.currentIncomeTax}
              k={"financialResults.currentIncomeTax"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.currentIncomeTax"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.permanentTax}
              k={"financialResults.permanentTax"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.permanentTax"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.changeTaxLiabilities}
              k={"financialResults.changeTaxLiabilities"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.changeTaxLiabilities"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.changeTaxAssets}
              k={"financialResults.changeTaxAssets"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.changeTaxAssets"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.netIncomeOther}
              k={"financialResults.netIncomeOther"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.netIncomeOther"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.netIncomeTotal}
              k={"financialResults.netIncomeTotal"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.netIncomeTotal"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.resultFromAssets}
              k={"financialResults.resultFromAssets"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.resultFromAssets"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.resultFromOtherOperations}
              k={"financialResults.resultFromOtherOperations"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.resultFromOtherOperations"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.financialResultTotal}
              k={"financialResults.financialResultTotal"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.financialResultTotal"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.dilutedEarningsPerShare}
              k={"financialResults.dilutedEarningsPerShare"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.dilutedEarningsPerShare"
              )}
              level={3}
            />
          </NestedRow>

          <NestedRow style={{ height: "50px" }}>
            <EditRow
              finData={data.financialResults.basicEarningsPerShare}
              k={"financialResults.basicEarningsPerShare"}
              isEdit={isEdit}
              firstCell={t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.basicEarningsPerShare"
              )}
              level={3}
            />
          </NestedRow>

          <TableRow>
            <FirstCell level={2}>
              {t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.fnsStatus"
              )}
            </FirstCell>
            {transformedData.fnsStatus.map((el, index) => (
              <TextCell key={index}>
                <div>{el === "" ? "" : t(`FnsStatus.${el}`)}</div>
              </TextCell>
            ))}
          </TableRow>

          <TableRow>
            <FirstCell level={2}>
              {t(
                "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements.taxationSystem"
              )}
            </FirstCell>
            {transformedData.taxationSystem.map((el, index) => (
              <TextCell key={index}>
                <div>{el ? t(`taxationSystem.${el}`) : ""}</div>
              </TextCell>
            ))}
          </TableRow>
        </TableBody>
      </DefaultTable>
    </>
  );
};

export default FinTable2;
