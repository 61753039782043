import { downloadFile } from "@Dao/api";

export const downloadFile2Func = async (id) => {
  await downloadFile(`/api/Customer/${id}/risk-signals/portfolio`);
};

export const downloadFileFunc = async (clientId) => {
  await downloadFile(`/api/Client/${clientId}/risk-signals/form`);
};

export const downloadFinDataTemplateFileFunc = async (id) => {
  await downloadFile(`/api/Customer/reporttemplate/${id}`);
};
